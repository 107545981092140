import ReactModal from "react-modal";

ReactModal.setAppElement("body");

export default function PinInfoModal({ info, closeModal }) {
  return (
    <div>
      <ReactModal isOpen={info.length > 0} contentLabel="Extra Info">
        <button onClick={closeModal} className="btn-close">
          X
        </button>
        <div
          dangerouslySetInnerHTML={{ __html: info }}
          className="modal-content"
        ></div>
      </ReactModal>
    </div>
  );
}
