import { useState } from "react";
import PinInfoModal from "./PinInfoModal";
import markers from "./markers";
import worldData from "./world.json";

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const continent_colors = {
  "North America": "#54acd2",
  "South America": "#f7da64",
  Europe: "#1bbc9b",
  Asia: "#ec6b56",
  Oceania: "#a38f84",
  Australia: "#a38f84",
  Africa: "#9265b8",
};

const MapChart = () => {
  const [currentInfo, setCurrentInfo] = useState("");

  return (
    <div className="bounding-div">
      <h1>Wereldkaart</h1>
      <div className="worldmap">
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            center: [0, 40],
            scale: 130,
          }}
        >
          <Geographies geography={worldData}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={continent_colors[geo.properties.continent]}
                  tabIndex="-1"
                />
              ))
            }
          </Geographies>
          {markers.map(({ name, coordinates, info }) => (
            <Marker
              key={name}
              coordinates={coordinates}
              onClick={() => {
                setCurrentInfo(info);
              }}
              tabIndex="0"
            >
              <g
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(-12, -24)"
                pointerEvents="bounding-box"
              >
                <svg
                  width="20"
                  height="32"
                  viewBox="0 0 32 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 0C6.02999 0 0.384995 5.645 0.384995 12.615C0.384995 19.585 13 40 13 40C13 40 25.615 19.585 25.615 12.615C25.615 5.645 19.97 0 13 0ZM13 19.395C9.25499 19.395 6.21999 16.36 6.21999 12.615C6.21999 8.87 9.25499 5.835 13 5.835C16.745 5.835 19.78 8.87 19.78 12.615C19.78 16.36 16.745 19.395 13 19.395Z"
                    fill="black"
                  />
                </svg>
              </g>
            </Marker>
          ))}
        </ComposableMap>
      </div>
      <PinInfoModal
        info={currentInfo}
        closeModal={() => {
          setCurrentInfo("");
        }}
        ariaHideApp={false}
      ></PinInfoModal>
      <button
        onClick={() => {
          setCurrentInfo(
            "Je hebt de simulator helemaal doorlopen. Je mag nu dit scherm sluiten en teruggaan naar de EDUbox.",
          );
        }}
      >
        Ik ben klaar
      </button>
    </div>
  );
};

export default MapChart;
